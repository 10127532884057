import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Linkify from 'react-linkify'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Box, Flex, DesktopContainer, MobileContainer } from './ui/BuildingBlocks'
import Button from './ui/Button'
import Spinner from './ui/Spinner'
import { colors, smallBoxShadow } from './ui/helpers.js'
import { urlToString } from './ui/formatters.js'
import countryToIso2 from '../../constants/countryToIso2'
import Image from './ui/Image'
import { H1, H2, H3, SeoH2, SeoH4, Span, Text, LinkSpan } from './ui/Font'
import OnlineIcon from './ui/OnlineIcon'
import { translate, countryTranslate, activeCountries } from '../../constants/translations'

const Container = styled(MobileContainer)`
  height: 60px;
  background-color: ${colors.darkGrey};
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  position: sticky;
  top: 0;
  z-index: 1;
`

const MobileMenu = ({ country, iso2 }) => {
  return (
    <Container>
      <Link to={`${iso2 === 'en' ? '' : `/${iso2}`}/`}>
        <Flex width="auto" alignItems="flex-end">
          <Image mr="14px" width="30px" height="30px" src="/home.svg" />
          <Text bold color="white">
            Home
          </Text>
        </Flex>
      </Link>
      <Link to={`${iso2 === 'en' ? '' : `/${iso2}`}/${countryTranslate(country, iso2)}/`}>
        <Flex width="auto" alignItems="flex-end">
          <Text bold mr="14px" color="white">
            All Numbers
          </Text>
          <Image alt="telephone icon" mt="1px" width="30px" height="30px" src="/number.svg" />
        </Flex>
      </Link>
    </Container>
  )
}
export default MobileMenu
